import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/women.jpg"
import Layout from "../../components/layoutSurpriseCustom"
import PageHeaders from "../../components/pageHeaders"
import SEO from "../../components/seo"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const IndexPage = () => (
    <Layout ImgSrc={BgImage} MainText="Women @ Cross Church">
        <SEO title="Women @ Cross Church" />
        <PageHeaders
            css={css`
        display: flex;
      `}
        >
            Women @ Cross Church
        </PageHeaders>
        <HeaderText></HeaderText>
        <HeaderText></HeaderText>
        <ParagraphText>
            Women at Cross Church is specifically designed for women to easily engage
            no matter where they are in their spiritual journey. Come join Cross
            Church Women where you’ll make friends as well as find communities that
            fit all different seasons of life.
            <br />
            <br />
            Make sure to follow us on Instagram and Facebook for the most updated news and schedule of events.
            <br />
            <br />
            For any other questions reach out via email to Kristen Wood & Robbie Gittings at <a href="mailto:women@crosschurchcares.com">women@crosschurchcares.com</a>
        </ParagraphText>
        <ParagraphText
            css={css`
        margin-top: 2rem;
        text-align: center;
      `}
        >
            <a href="https://www.facebook.com/WomenatCrossChurch/">
                <svg
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    css={css`
            height: 44px;
            fill: #333;
          `}
                >
                    <path d="m75 512h167v-182h-60v-60h60v-75c0-41.355469 33.644531-75 75-75h75v60h-60c-16.542969 0-30 13.457031-30 30v60h87.292969l-10 60h-77.292969v182h135c41.355469 0 75-33.644531 75-75v-362c0-41.355469-33.644531-75-75-75h-362c-41.355469 0-75 33.644531-75 75v362c0 41.355469 33.644531 75 75 75zm-45-437c0-24.8125 20.1875-45 45-45h362c24.8125 0 45 20.1875 45 45v362c0 24.8125-20.1875 45-45 45h-105v-122h72.707031l20-120h-92.707031v-30h90v-120h-105c-57.898438 0-105 47.101562-105 105v45h-60v120h60v122h-137c-24.8125 0-45-20.1875-45-45zm0 0" />
                </svg>
            </a>
            <a href="https://instagram.com/womenatcrosschurch?utm_medium=copy_link">
                <svg
                    viewBox="0 0 512 512"
                    css={css`
            height: 44px;
            fill: #333;
            margin-left: 2rem;
          `}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="m75 512h362c41.355469 0 75-33.644531 75-75v-362c0-41.355469-33.644531-75-75-75h-362c-41.355469 0-75 33.644531-75 75v362c0 41.355469 33.644531 75 75 75zm-45-437c0-24.8125 20.1875-45 45-45h362c24.8125 0 45 20.1875 45 45v362c0 24.8125-20.1875 45-45 45h-362c-24.8125 0-45-20.1875-45-45zm0 0" />
                    <path d="m256 391c74.4375 0 135-60.5625 135-135s-60.5625-135-135-135-135 60.5625-135 135 60.5625 135 135 135zm0-240c57.898438 0 105 47.101562 105 105s-47.101562 105-105 105-105-47.101562-105-105 47.101562-105 105-105zm0 0" />
                    <path d="m406 151c24.8125 0 45-20.1875 45-45s-20.1875-45-45-45-45 20.1875-45 45 20.1875 45 45 45zm0-60c8.269531 0 15 6.730469 15 15s-6.730469 15-15 15-15-6.730469-15-15 6.730469-15 15-15zm0 0" />
                </svg>
            </a>
        </ParagraphText>
        <PageHeaders
            css={css`
        display: flex;
        margin-top: 4rem;
      `}
        >
            Sign-Up Below
        </PageHeaders>
        <iframe
            src="https://cccares.formstack.com/forms/nextsteps"
            title="Connect - Cross Church"
            width="100%"
            height="1200px"
            frameBorder="0"
        ></iframe>

    </Layout>
)

export default IndexPage
